.container {
	z-index: 20;
	visibility: hidden;
	position: fixed;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.4);
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 0;
	transition: opacity 0.2s;
}

.contentContainer {
	width: 100vw;
	max-width: 1080px;
	height: auto;
	max-height: 100%;
	padding: 24px;
}

.fullscreen {
	width: 1400px;
	max-width: calc(100vw - 24px);
}

.content {
	width: 100%;
	height: auto;
	background-color: #fff;
	border-radius: 8px;
	overflow-y: auto;
}

.visible {
	visibility: visible;
	opacity: 1;
}
