.container {
    margin-left: 10px;
    margin-right: 10px;
}

.centerRow, .backButton {
    display: flex;
    align-items: center;
}

.backButton {
    max-height: 20px;
    margin-right: 10px;
}

.fromEdit {
    max-width: 500px;
    display: flex;
    margin-left: 10px;
}

.imageEdit {
    width: 100px;
    display: flex;
    justify-content: center;
}

.userList {
    max-height: 400px;
    overflow-y: auto;
    width: 300px;
}

.groupButtons {
    margin-top: 15px;
}

.nameCell {
    background-color: white;
    padding-right: 5px;
    padding-left: 5px;
    border-radius: 5px;
}

.contentContainer {
    max-width: 1000px;
    margin-right: 10px;
    display: flex;
    flex-flow: column;
    min-width: 0;
}
