.container {

}

.item {
    display: flex;
    border-bottom: 1px #f2f2f2 solid;
    padding: 10px 5px;
    background: white;
}
.item:hover {
    filter: contrast(90%);
    cursor: pointer;
}

.text {
    margin-left: 5px;
    height: max-content;
    margin-top: auto;
    margin-bottom: auto;
    font-size: 1.2em;
}
