.input {
    background-color: white;
    transition: background-color .3s ease;
}

.input:hover {
    background-color: white;
}

.input:focus {
    background-color: #e5e5e5;
}

.container {
    display: flex;
    justify-content: left;
    flex: 0.9;
}

.row {
    display: flex;
    flex: 1;
    min-width: 0;
}

.userImage {
    border-radius: 50%;
    border: 1px solid;
    object-fit: cover;
    width: 100px;
    height: 100px;
}

.formContainer {
    max-width: 500px;
    min-width: 200px;
    display: flex;
    margin-left: 20px;
}

.extraButtonContainer {
    display: flex;
    height: min-content;
    width: 70px;
    justify-content: space-between;
    align-items: center;
    margin-top: 47px;
    margin-left: auto;
}
