.table {
    width: 100%;
    border-collapse: collapse;
}

.th {
    text-align: left;
    padding: 8px 4px;
}

.td {
    padding: 8px 4px;
    transition: height 0.3s ease;
}

.td:hover {
    cursor: pointer;
}

.clickable {
    cursor: pointer;
}

.tr:nth-of-type(odd) {
    background-color: rgb(250, 251, 252);
}

.tr:hover {
    background-color: rgba(0, 82, 204, 0.09);
}

.tr {
    transition: height 0.3s ease;
}

.activityIndicator {
    display: flex;
    justify-content: center;
    margin-top: 12px;
}

.iconFolder{
    padding-right: 5px
}
