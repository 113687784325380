.textBoxClass {
    width: 100%;
    resize: none;
    min-height: 25px;
    max-height: 150px;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    color: #172b4d;
    font-size: 14px;
    line-height: 1.5em;
    border-radius: 20px;
    border-right-style: solid;
    border-left-width: 0;
    border-right-width: 15px;
    border-top: none;
    border-bottom: none;
    height: 25px;
    white-space: pre-wrap;
}

.textBoxClass:disabled {
    background-color: #e3e3e3;
}

.topContainer {
    position: relative;
    transition: all 0.3s;
}

.topContainerHide {
    min-height: 0;
    height: 0;
    opacity: 0;
}

.topContainerShow {
    min-height: 80px;
    height: auto;
    opacity: 1;
}

.topBackground {
    position: absolute;
    background-color: darkgray;
    left: 0;
    right: 0;
    top: 0;
    bottom: -20px;
    padding-top: 10px;
    padding-left: 5px;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
}

.topContentContainer {
    position: relative;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 40px;
    display: flex;
    align-items: flex-end;
}

.topContentContainerHidden {
    /*min-height: 80px;*/
    height: 0;
}

.topContentContainerShow {
    min-height: 80px;
    height: 100%;
}


.topCloseButton {
    position: absolute;
    top: 0;
    right: 4px;
    background-color: white;
    border-radius: 50%;
    z-index: 1
}

.buttonContainer {
    display: flex;
    margin: auto auto auto 30px;
    justify-content: center;
    align-content: center;
}
