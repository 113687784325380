.container {
	margin: 48px 0px;
}

.row {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.row button {
	margin: 24px 0px 12px 12px;
}

.dropdown {
	margin-bottom: 24px;
}

.template {
	margin: 12px 0px 32px;
}

.datePicker {
	margin-top: 24px;
	display: flex;
	flex-direction: row;
}

.datePicker div {
	margin-right: 24px;
}
