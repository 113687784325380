.toolbar {
    background: #eee;
    border-bottom: solid 2px #ccc;
    display: flex;
    padding: 2px;
}

.separator {
    width: 1px;
    height: 1.5em;
    margin: 0 2px;
    align-self: center;
    background: #bbb;
}

.button {
    background: none;
    border: none;
    padding: 8px;
    font-size: 16px;
    display: flex;
    color: #666;
    border-radius: 4px;
    transition: all .3s ease-in-out;
}

.button[disabled] {
    opacity: 0.5;
}

.button:not([disabled]):hover {
    background: #0001;
}

.buttonActive {
    color: #000;
}

.emojiPicker {
    z-index: 1000;
    position: absolute;
    opacity: .9;
}
