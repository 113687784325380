.container {
	position: relative;
	z-index: 2;
}

.content {
	position: absolute;
	bottom: 20px;
	background-color: #fff;
	padding: 16px;
	border-radius: 3px;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
}

.overlay {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.07);
	z-index: 1;
}
