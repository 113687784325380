.dropDownWrapper {
    border: 1px solid rgb(179 179 179);
    position: absolute;
    top: -10%;
    left: 106%;
    width: 22vw;
    box-shadow: 8px 11px 17px 0 rgba(0, 0, 0, 0.35);
    border-radius: 3px;
    background-color: white;
    padding: 20px 25px;
    z-index: 2;
    min-height: 50vh;
    height: 74vh;
    max-height: 640px;
}

@media screen and (max-width: 1024px) {
    .dropDownWrapper {
        width: 37vw;
        left: 40%;
    }
}

.simpleBarContainer{
    position: relative;
    height: 100%;
}

.simpleBarWrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.editIcon {
    font-size: 1.2vw;
    margin-left: 0.5vw;
    cursor: pointer;
    color: #505f79;
}

.editIcon:active {
    color: grey;
}

.formWrapper {
    width: 80%;
    position: relative;
}

.returnIcon {
    position: absolute;
    font-size: 10px;
    width: 0.85vw;
    top: 32%;
    right: 4%;
    cursor: pointer;
}

.paddingRight {
    padding-right: 1.7vw;
}

.label {
    font-size: 15px;
    opacity: 0.5;
    margin: 7px 0;
    font-weight: 500;
    white-space: pre-wrap;
}

.labelStyles {
    height: 45px;
    line-height: 24px;
}

.checkBox_height {
    height: 42px;
}

.number{
    position: absolute;
    font-size: 12px;
    right: 0;
}

.closeButton {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 18px;
    border-radius: 50%;
    cursor: pointer;
    transition: 0.4s;
}

.closeButton:hover {
    background-color: rgba(234, 236, 240, 0.62);
}

.colorPicker {
    margin: 15px;
}

.icon {
    font-size: 32px;
    margin: auto;
}

.buttonWrapper {
    align-items: center;
    display: flex;
    place-content: space-between;
    margin-top: 15px;
}

.editCalendarNameWrapper {
    display: flex;
    width: 95%;
    place-content: center;
    position: relative;
    margin: auto;
}

.calendarName {
    font-size: 21px;
    font-weight: 600;
    margin-bottom: 1.7vh;
}

.title {
    font-size: 14px;
    margin-bottom: 10px;
    font-weight: 600;
}

.randomButtonTitleWrapper {
    display: flex;
    width: 52%;
}

.randomButtonTitle {
    font-size: 10px;
    width: fit-content;
}

.text {
    margin: 4px 0;
    line-height: 2em;
    min-height: 30px;
}

.row {
    display: flex;
    align-items: center;
    margin-top: 12px;
}

.deleteButton {
    background-color: #ed5e68;
}

.deleteButton:hover {
    filter: contrast(60%);
    background-color: #ed5e68;
}
