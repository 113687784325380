.container {
    display: flex;
    /*height: 72px;*/
    min-height: 72px;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    background: white;
    border-radius: 5px;
    transition: border-color 0.3s ease-in-out;
    border: 1px solid transparent;;
}

.active {
    border-color: var(--secondaryColor,'black');
}

.container:hover {
    filter: contrast(90%);
    cursor: pointer;
}

.title {
    font-size: 1.2em;
    margin-right: 48px; /* Länge des Datums,damit nicht der Title im Datum steht */
}

.subtitle {
    font-size: .95em;
    color: darkgray;
    margin-right: 5px;
}

.dateContainer {
    position: absolute;
    right: 10px;
    text-align: right;
}

.date {
    color: darkgray;
    text-align: right;
    font-size: 0.9em;
}



.userImage, .groupImage {
    width: 45px;
    height: 45px;
    background-color: lightgray;
}

.userImage {
    border-radius: 50%;
}

.groupImage {
    border-radius: 25%;
}

.bubbleContainer {
    display: flex;
    justify-content: right;
    align-content: flex-end;
    height:24px;
    position: relative;
}

@media screen and (max-width: 900px) {
    .container {
        display: block;
        align-items: unset;
        padding-left: 0;
        padding-right: 0;
    }

    .userImage {
        margin: auto;
    }

    .groupImage {
        margin: auto;
    }

    .title {
        margin-right: 0;
    }

    .subtitle {
        margin-right: 0;
    }

    .dateContainer {
        all: unset;
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
    }

    .date {
        text-align: unset;
    }

    .bubbleContainer {
        all: unset;
        position: relative;
        width: 30px;
    }

}
