.dreieckLeft, .dreieckRight,.dreieckTop,.dreieckBottom{
    width: 0px;
    height: 0px;
    -webkit-transform:rotate(360deg);
    border-style: solid;
    position: relative;
    z-index: 2;
}
.dreieckTop{
    border-color: #f4f5f7 transparent transparent transparent;
    border-width: 15px 7.5px 0 7.5px;
}
.dreieckBottom{
    border-color: transparent transparent #f4f5f7 transparent;
    border-width: 0 7.5px 15px 7.5px;
}
.dreieckRight {
    border-color: transparent transparent transparent #f4f5f7;
    border-width: 7.5px 0 7.5px 15px;
}
.dreieckLeft {
    border-color: transparent #f4f5f7 transparent transparent;
    border-width: 7.5px 15px 7.5px 0;
}
.popUpContent {
    background-color: #f4f5f7;
    padding: 5px;
    border-radius: 5px;
}
