.replayContainer,.replayTitleText {
   border-radius: 5px;
    padding: 10px 5px;
}

.replayContainer {
    background-color: white;
    min-width: 50%;
    margin-bottom: 5px;
}

.replayText {
    float: left;
    white-space: pre-wrap;
}

.replayTitleText {
    background-color: lightgray;
    width: min-content;
    min-width: 40px;
}

.replayImage {
    height: 100px;
}

.sendLoadContainer {
    margin-left: 5px;
    margin-top: 4px;
    background-color: white;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}