.container {
	flex: 1;
	height: 50px;
	margin-bottom: 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.text {
	flex: 1;
	font-size: 2.1em;
	font-weight: 600;
}

.avatar {
	height: 50px;
	width: 50px;
	border: none !important;
	background: transparent;
	border-radius: 100%;
	overflow: hidden;
	font-size: 10px;
	text-align: center;
	line-height: 50px;
	text-overflow: ellipsis;
	white-space: nowrap;
	margin-right: 10px;
}

@media screen and (max-width: 600px) {
	.text {
		font-size: 1.7em;
	}
}