.container {
	width: 100%;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.logo {
	width: 200px;
	max-width: 60%;
	margin: 24px;
	object-fit: contain;
}
