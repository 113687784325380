.calendarFrameWrapper {
    text-align: center;
    display: contents;
}

.hideLink {
    position: absolute;
    width: 100%;
    height: 40px;
    top: 91.6%;
    background-color: #ffffff;
}

.background {
    background: black;
}
