.container {
	position: relative;
}

.buttonContainer {
	display: flex;
	justify-content: flex-end;
}

.addButton {
	margin-top: 20px;
}

.emailInput {
	margin-top: 32px;
	margin-bottom: 32px;
}

.code {
	margin-top: 10px;
}

.checkBox {
	margin-top: 10px;
}
