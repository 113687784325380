.containerWrapper {
    position: relative !important;
}

.container{
    position: absolute;
    inset: 0;
}

.head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 2px;
    transition: border-color 0.3s ease;
}

.head:hover {
    cursor: pointer;
}


.dropDownContent {
    position: relative;
    z-index: 5;
    background-color: white;
    border-top: 0;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    border-bottom: 1px solid transparent;
    transition: border 0.3s ease;
}
.dropDownContentHide {
    border-color: transparent;
}
.dropDownContentShow {
    border-color: black;
    height: auto;
}


.item {
    padding-left: 2px;
}

.item:nth-child(odd){
    background-color: #edeff2;
}

.item:hover{
    cursor: pointer;
    background-color: #c0d1ee;
}