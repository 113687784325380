.container {
	display: flex;
	flex-direction: row;
	margin-top: 24px;
	margin-left: -8px;
	margin-right: -8px;
	height: 0;
	opacity: 0;
	transition: 0.2s ease-in;
	max-height: 30vh;
	position: relative;
}

.imageContainer {
	position: relative;
	margin-left: 8px;
	margin-right: 8px;
	border-radius: 4px;
	overflow: hidden;
	max-height: 30vh;
	flex: 1 1 0;
}

.image {
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center;
}

.more {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.5);
}

.moreText {
	color: #fff !important;
	opacity: 0.9;
	font-size: 1.4em !important;
	font-weight: 300 !important;
}

.editButton {
	margin-top: 8px;
}
