.loadingScreen {
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo {
    width: 200px;
    object-fit: contain;
    margin: 32px;
}

.activeColor {
    border-top-color: #cbcbcb;
}