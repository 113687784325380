.dropDownHead {
    background-color: #edeff2;
    padding: 5px 10px;
    border-radius: 5px;
    transition: all 0.3s ease;
}
.dropDownHead:hover {
    cursor: pointer;
    background-color: #dce0e1;
}

.row,.lastRow {
    display: flex;
    align-items: center;
}

.lastRow {
    justify-content: space-between;
    margin-top: auto;
}