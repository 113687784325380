.container {
	display: flex;
	flex: 1;
	min-height: 100vh;
	justify-content: center;
	align-items: center;
}

.box {
	max-width: 400px;
	display: flex;
	flex-direction: column;
}

.logo {
	max-width: 200px;
	align-self: center;
	margin: 24px;
	margin-bottom: 32px;
}

.button {
	justify-content: center;
}
