.buttonContainer {
	display: flex;
	justify-content: flex-end;
	margin-top: 12px;
}

.saveButton {
	margin-left: 12px;
}

.userEmail {
	margin-bottom: 24px;
}
