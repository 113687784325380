.foldersContainer{
    padding: 10px;
}

.folders {
    font-size: large;
    width: fit-content;
}

.folders:hover{
    cursor: pointer;
}

.div_icon_folders {
    display: flex;
}

.div_icon_foldersByGroups {
    display: flex;
    margin: 10px 0;
}

.div_icon_folders_infotouch {
    margin: 60px 0 60px 0;
    display: flex;
    width: 250px;
}

.icons {
    margin-right: 10px;
    font-size: 30px;
}

.labels {
    font-size: 19px;
    line-height: 28px;
}