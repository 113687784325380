.container {
	display: flex;
	margin-right: 20px;
}

.item {
	padding: 4px 8px;
	cursor: default;
	transition: 0.3s;
}

.item:hover {
	background-color: rgba(9, 30, 66, 0.04);
}

.selected {
	display: flex;
	flex-direction: row;
	align-items: center;
	min-width: 120px;
	cursor: default;
}

.button {
	margin: 0;
}
