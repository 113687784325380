.button {
	font-size: 22px;
	margin: 8px;
	padding: 8px;
	border-radius: 40px;
	cursor: pointer;
}

.button:hover {
	background-color: rgba(9, 30, 66, 0.09);
}

.button:active {
	background-color: rgba(0, 82, 204, 0.09);
}
