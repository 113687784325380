.container {
	margin-top: 16px;
	padding-bottom: 4px;
}

.text {
	margin-bottom: 0px !important;
}

.input {
	all: unset;
	width: 100%;
	font: inherit;
	font-size: 14px;
	font-weight: 400;
	color: #172b4d;
	margin-bottom: -6px;
	max-height: 40vh;
	border-bottom: 1px solid var(--secondaryColor, #000);
	transition: 0.2s;
	white-space: pre-wrap;
}
.input:hover {
	border-bottom-color: var(--primaryColor, #000);
}