.dropzone {
	all: unset;
	border: 3px dashed rgba(9, 30, 66, 0.14);
	border-radius: 3px;
	height: 170px;
	margin-top: 8px;
	margin-bottom: 12px;
	padding: 24px;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: 0.3s;
}

.dropzone:hover {
	background-color: rgba(0, 82, 204, 0.09);
	border-color: rgba(9, 30, 66, 0.21);
}

.dropzone:active {
	border-color: rgba(0, 82, 204, 0.7);
}

.filesContainer {
	display: flex;
	flex-wrap: wrap;
}

.file {
	width: 50%;
	padding-right: 12px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
}

.fileName {
	margin-left: 8px;
}
