.row {
	margin-top: 32px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.btnRow {
	display: flex;
	flex-direction: row;
	align-items: center;
}
