.link {
	color: rgb(80, 95, 121);
}

.link:visited {
	color: rgb(80, 95, 121);
}

.link:hover {
	color: rgb(7, 71, 166);
}

.link:active {
	color: rgb(7, 71, 166);
}
