.label {
    font-size: 15px;
    font-weight: 500;
    white-space: pre-wrap;
    background-color: white;
    padding: 10px 0 3px 15px;
    color: #505d71;
}

.dropDownWrapper {
    border: 1px solid rgb(179 179 179);
    position: absolute;
    top: 90%;
    width: 25%;
    box-shadow: 8px 11px 17px 0 rgba(0, 0, 0, 0.35);
    border-radius: 3px;
}

.dropDownDivToBack {
    background: white;
}

.dropDownDivToBack:hover {
    background-color: rgba(244, 245, 247, 0.7);
}

.dropDownDiv {
    display: flex;
    position: relative;
    padding: 10px;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
}

.dropDownLabel {
    flex: 1;
    margin-left: 4px;
    max-width: 8vw;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.dots {
    right: 0;
    top: 36%;
    cursor: pointer;
}

.SelectWrapper {
    grid-area: sidebar;
    position: relative;
}

@media (min-width: 430px) {
    .modal_width {
        width: fit-content;
    }
}

.calendarsWrapper {
    margin: 15px
}

.calendarsDivWrapper {
    display: flex;
    width: 100%;
    height: 25px;
    margin: 15px 0;
}

.iconExample {
    font-size: 24px;
}

.rowButton {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top:20px;
}

.cancelButton {
    margin-right: 8px;
}
