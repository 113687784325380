.container {
	height: 24px;
	overflow: hidden;
	display: flex;
	align-items: center;
	fill: #fff;
	background-color: rgba(9, 30, 66, 0.002);
	cursor: pointer;
}

.container:hover .borderBox {
	background-color: rgba(9, 30, 66, 0.07);
}

.container:hover svg {
	color: rgb(0, 101, 255);
}

.container:active .borderBox {
	background-color: rgba(0, 82, 204, 0.09);
	border-color: transparent;
}

.container:active svg {
	color: rgb(7, 71, 166);
}

.boxContainer {
	width: 24px;
	height: 24px;
}

.checked {
	margin: -4px;
	color: rgb(0, 82, 204);
	transition: color 0.3s;
}

.borderBox {
	margin: 4px;
	width: 12px;
	height: 12px;
	border: 2px solid rgba(9, 30, 66, 0.09);
	border-radius: 3px;
	transition: background-color 0.3s;
}
@media screen and (min-width: 975px){
	.borderBoxRound {
		margin: 4px;
		width: 15px;
		height: 15px;
		border-radius: 10px;
		transition: background-color 0.3s;
	}
}

@media screen and (max-width: 975px){
	.borderBoxRound {
		margin: 4px;
		width: 10px;
		height: 10px;
		/*border: 2px solid rgba(9, 30, 66, 0.09);*/
		border-radius: 10px;
		transition: background-color 0.3s;
	}
}

@media screen and (max-width: 750px){
	.borderBoxRound {
		margin: 4px;
		width: 8px;
		height: 8px;
		/*border: 2px solid rgba(9, 30, 66, 0.09);*/
		border-radius: 10px;
		transition: background-color 0.3s;
	}
}

@media screen and (max-width: 675px){
	.borderBoxRound {
		margin: 0px;
		width: 7px;
		height: 7px;
		/*border: 2px solid rgba(9, 30, 66, 0.09);*/
		border-radius: 10px;
		transition: background-color 0.3s;
	}
}

@media screen and (max-width: 350px){
	.borderBoxRound {
		margin: 0px;
		width: 6px;
		height: 6px;
		/*border: 2px solid rgba(9, 30, 66, 0.09);*/
		border-radius: 10px;
		transition: background-color 0.3s;
	}
}

.label {
	line-height: 20px;
	padding: 2px 2px;
	cursor: default;
}
