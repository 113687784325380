.fc .fc-button {
    font-size: 0.85em;
    background-color: #f2f2f8;
}

.fc .fc-button-primary {
    color: black;
}

.fc .fc-scrollgrid-liquid {
    margin-top: 10px;
}

.fc {
    display: contents!important;
}

.fc > .fc-header-toolbar {
    grid-area: header;
}
.fc > .fc-view-harness {
    grid-area: calendar;
}

.event-state--tentative {
    opacity: 0.75;
    position: relative;
    --stripe-opacity: 0.2;
}

.event-state--tentative:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: repeating-linear-gradient(
            135deg,
            transparent,
            transparent 10px,
            rgba(0,0,0,var(--stripe-opacity)) 10px,
            rgba(0,0,0,var(--stripe-opacity)) 20px
    );
}
.fc-timegrid {
    z-index: 1;
}

.fc-day.fc-day-sat, .fc-day.fc-day-sun {
    background: rgba(0, 0, 125, 0.04);
}

.fc .fc-toolbar-title {
    margin-bottom: 8px;
}

.fc .fc-toolbar.fc-header-toolbar {
    align-items: end;
}
