.iconReg {
    margin: 8px 10px 8px 9px;
    font-size: 20px;
}
.iconRegCalendars {
    font-size: 21px;
    margin-right: 5px;
}

/*.iconRegCalendars:hover {*/
/*    cursor: pointer;*/
/*    margin: 13px 8px 8px 8px;*/
/*    font-size: 24px;*/
/*}*/

.divWidth {
    display: flex;
    align-items: center;
}

.iconRegBeat {
    margin: 6px 7px 5px 7px;
    font-size: 24px;
}

.tippy {
    background-color: rgb(249, 247, 255);
    color: #0052cc;
    transform: scale(0.85);
}

.iconTextContainer {
    cursor: pointer;
    padding: 2px 10px
}

.iconTextContainer:hover {
    background-color: #eaecf0;
    border-radius: 2px;
}

.iconTextWrapper {
    display: flex;
}

.container_height {
    height: 75vh;
}

@media (min-width: 430px) {
    .container_width {
        width: 98%;
        margin: auto;
    }
    .modal_width {
        width: 590px;
    }
}

.simpleBarContainer{
    position: relative;
    height: 100%;
}

.simpleBarWrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.overflowHidden {
    overflow-x: hidden;
}

.cancelButton {
    margin-right: 8px;
}

.label {
    font-size: 15px;
    opacity: 0.5;
    margin: 7px 0;
    font-weight: 500;
    white-space: pre-wrap;
}

.labelStyles {
    height: 45px;
    line-height: 24px;
}

.checkBox_height {
    height: 38px;
}

.colorPicker {
    margin-top: 15px;
}

.divExampleWrapper {
    display: flex;
    position: absolute;
    margin: 0;
    top: 50%;
    left: 40%;
    transform: translate(-90px, 10px);
    width: 100%;
}

.iconExample {
    margin-top: -1px;
    font-size: 25px;
    margin-right: 5px;
}

.number{
    position: absolute;
    font-size: 12px;
    right: 0;
}

.icsWrapper {
    flex-direction: row;
    width: 100%;
    display: flex;
    align-items: center;
}

.icsIcon {
    font-size: 20px;
    margin-right: 10px;
}

.fileName {
    max-width: 50%;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.icsFileClose {
    font-size: 18px;
    cursor: pointer;
    margin-left: 8px;
    color: red;
    margin-top: 2px;
    border-radius: 50%;
}

.icsFileClose:hover{
    background-color: rgb(233, 235, 238);
}

.file{
    width: 9.3vw;
    position: absolute;
    top: -4px;
    left: 0;
    opacity: 0;
    font-size: 0;
}

.file:active {
    background-color: black;
    opacity: 1;
}

.file:hover{
    opacity: 0.55;
    background-color: #808080;
    font-size: 0;
}

.focus {
    background: white;
    border-radius: 50%;
    margin-top: 9px;
    width: 35px;
    height: 35px;
    animation-name: shadow;
    animation-duration: 2s;
}

@keyframes shadow {
    from {box-shadow: 0 0 8px 400px rgba(0 0 0 0.1)}
    to {box-shadow: 0 0 0 0 rgba(0 0 0 0)}
}

.formInput {
    opacity: 0.5;
    border-color: rgb(237, 239, 242);
    margin-bottom: 5px;
}

.urlInputIconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2vw;
    position: absolute;
    bottom: 5px;
    right: 1px;
    background: #ffffff;
    height: 38px;
}

.urlInputIconContainer{
    width: 50%;
    height: 55%;
}

.urlIcon:hover {
    background-color: rgba(234, 236, 240, 0.62);
    cursor: pointer;
    border-radius: 50%;
    box-shadow: 0 0 0 0.2rem rgba(234, 236, 240, 0.62);
}

@media screen and (max-width: 900px) {
    .urlInputIconWrapper {
        width: 3vw;
    }
}

.formInputMessage {
    border-color: #ff5500;
    opacity: 0.5;
    margin-bottom: 5px;
}

.formInputPadding {
    padding-right: 2vw;
}

@media screen and (max-width: 900px) {
    .formInputPadding {
        padding-right: 3vw;
    }
}

.buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 20px;
}

.responseContainer {
    width: 100%;
}


