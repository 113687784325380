.container {
	width: 100vw;
	height: 100vh;
	background-color: rgba(9, 30, 66, 0.04);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.content {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	max-width: 420px;
	margin: 24px;
	padding: 12px;
	background-color: #fff;
	border-radius: 3px;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.04), 0 6px 20px 0 rgba(0, 0, 0, 0.01);
}

.logo {
	width: 200px;
	max-width: 60%;
	margin: 24px;
	object-fit: contain;
}

.form {
	width: 100%;
}

.button {
	justify-content: center;
	margin-top: 16px;
}

.errorMessage {
	display: flex;
	justify-content: center;
}
