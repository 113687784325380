.img {
    margin: auto;
    max-height: 300px;
    object-fit: contain;
}

.img:hover {
    cursor: pointer;
}

.container {
    display: flex;
    position: relative;
    justify-content: center;
}
