.container {
	max-width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	overflow-y: unset;
}

.details {
	flex: 1;
	max-width: 100%;
}

.content {
	align-self: baseline;
}
