.ButtonContainer {
    display: flex;
    position: absolute;
    width: 100%;
    bottom: 10px;
    left: 10px;
}
.msg {
    color: red !important;
    margin-left: 10px;
}