.input {
    all: unset;
    font: inherit;
    color: #172b4d;
    width: 100%;
    border-bottom: 1px black solid;
}
.info {
    margin-top: 10px;
    margin-bottom: 15px !important;
}