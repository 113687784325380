.label {
	margin-top: 8px;
}

.noMarginTop {
	display: flex;
}

.input, .inputPassword {
	all: unset;
	border: 1px solid;
	border-radius: 3px;
	border-color: var(--secondaryColor, #000);
	padding: 8px;
	width: 100%;
	margin-top: 4px;
	margin-bottom: 4px;
	display: inline-block;
	box-sizing: border-box;
}
.inputPassword {
	padding-right: 30px;
}

.input:focus, .inputPassword:focus {
	border-color: var(--primaryColor, #000);
}

.input:hover, .inputPassword:hover {
	background-color: rgba(244, 245, 247, 0.2);
}
