.container {
    display: flex;
    position: relative;
    height: max-content;
    white-space: pre-wrap;
    padding-top: 2px;
    padding-bottom: 2px;
    overflow: hidden;
}
.container:hover .buttonsContainer{
    opacity: 1;
    transition: all 0.3s ease;
}
.msgBox {
    background-color: white;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    min-width: 140px;
    max-width: calc(100% - 300px);
    white-space: pre-wrap;
    padding: 5px 12px;
    position: relative;
    box-shadow: 1px 1px 1px 1px rgba(0,0,0,.2);
}
.text {
    white-space: pre-wrap;
    margin-right: 43px;
    word-wrap: break-word;
}
.title {
    color: #4f81a1;
}
.date {
    color: gray;
    font-size: 0.9em;
    text-align: right;
    position: absolute;
    bottom: 0;
    right: 11px;
}
.img {
    object-fit: contain;
    margin-bottom: 10px;
    max-height: 400px;
    max-width: 100%;
}
.dreieckLeft, .dreieckRight{
    width: 0px;
    height: 0px;
    -webkit-transform:rotate(360deg);
    border-style: solid;
    position: relative;
}
.dreieckLeft{
    border-color: transparent white transparent transparent;
    border-width: 0 15px 15px 0;
    z-index: 2;
}
.dreieckRight{
    border-color: white transparent transparent transparent;
    border-width: 15px 15px 0 0;
}
.buttonsContainer {
    display: flex;
    opacity: 0;
    align-items: center;
    transition: all 0.3s ease;
    flex: 1;
    min-width: 0;
    justify-content: right;
}
.reply {
    background-color: #ececec;
    border-radius: 4px;
    padding: 2px;
    color: #5a5a5a;
    cursor: pointer;
}
.bigIcon {
    font-size: 2.3em;
}
.smallIcon {
    font-size: 1em;
}

.renderButtons {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #fff;
    box-shadow: 0 0 5px 0 #a4a4a4;
    cursor: pointer;
    transition: all .3s ease;
}

.replayHead {
    display: flex;
    justify-content: space-between;
    color: black;
}

.replayTitle,.replayText {
    background-color: white;
    padding: 1px 4px;
    border-radius: 5px;
}

.replayTitle{
    min-width:60px;
}

.replayText{
    margin-top: 2px;
}

.replayImage {
    width: 40px;
    object-fit: contain;
}

.row {
    display: flex;
    align-items: center;
}
