.container {
	height: 0px;
	transition: 0.2s;
	overflow: hidden;
}

.visible {
	height: 24px;
}

.text {
	color: #dd403e !important;
}
