.reply, .file {
    border-radius: 4px;
    padding: 2px;
    color: #5a5a5a;
    cursor: pointer;
    display: flex;
    align-items: center;
}
.reply{
    margin-top: 2px;
    background-color: white;
}

.file {
    background-color: #ececec;
    margin-bottom: 15px;
}
.bigIcon {
    font-size: 2.3em;
}
.smallIcon {
    font-size: 1em;
}