.img {
    object-fit: contain;
    margin-bottom: 10px;
    max-width: 100%;
    margin-right: 10px;
}

.container {
    position: relative;
}

.overlay {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    background: rgb(0, 0, 0);
    width: 100%;
    opacity:0.75;
    color: white;
    font-size: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.overlay:hover {
    cursor: pointer;
}

.imgCon{
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
}
