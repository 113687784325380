.app {
	display: flex;
	align-items: flex-start;
    height: 100%;
}

@media screen and (max-width: 600px) {
	.app {
		flex-direction: column;
	}
}

.loadingScreen {
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #f4f5f7;
	position: fixed;
	inset: 0;
	z-index: 10;
}

.logo {
	width: 200px;
	object-fit: contain;
	margin: 32px;
}

.activeColor {
	border-top-color: #cbcbcb;
}
