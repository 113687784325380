.head {
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    height: 40px;
}

.cssVarsMain {
    background-color: var(--primaryColor,#FFF);
    color: var(--primaryTextColor,#000);
}

.cssVarsSub {
    background-color: var(--secondaryColor,#000);
    color: var(--secondaryTextColor,#FFF);
}