.tk_ChatButton {
    display: flex;
	flex-direction: row;
	align-items: center;
	text-align: center;
	padding-left: 8px;
	padding-right: 8px;
	margin-top: 4px;
	margin-bottom: 4px;
	border-radius: 3px;
	box-sizing: border-box;
	transition: 0.3s;
	min-height: 30px;
}
.tk_ChatButton:hover {
    cursor: pointer;
    filter: brightness(70%);
}