.container {
    position: relative;
    border-radius: 50%;
}
.disContainer {
    position: relative;
    border-radius: 50%;
}
.middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
    cursor: pointer;
    z-index: -1;
}

.image, .disImage {
    object-fit: cover;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    transition: 0.5s ease;
}

.image:hover{
    opacity: 0.3;
    cursor: pointer;
}

.image:hover + .middle {
    opacity: 1;
}

.text {
    color: black;
    font-size: 2em;
    cursor: pointer;
}
