.outer {
	min-height: 100vh;
}

.container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.logo {
	width: 200px;
	max-width: 60%;
	margin: 24px;
	object-fit: contain;
}

.storeLogo {
	max-width: 170px;
	margin: 24px;
}

.registerButton {
	height: 40px;
	margin-left: 12px;
}

.errorMessage {
	margin-top: 8px;
}

.text {
	margin-top: 24px;
}
