.table {
    display: flex;
    flex-direction: column;
    min-width: 240px;
}

.tbody {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%
}

.tr {
    flex: 1;
    display: flex;
    justify-content: space-between
}

.th {
    flex: 1;
    padding: 8px;
    font-weight: 500
}

.td {
    flex: 1;
    padding: 8px;
    color: rgb(80, 95, 121);
    transition: 0.3s;
    border-radius: 3px;
    cursor: default;
    user-select: none;
    text-align: center;
}

.td:hover {
    background-color: rgba(9, 30, 66, 0.09)
}

.td:active{
    color: rgb(0, 82, 204);
    background-color: rgba(0, 82, 204, 0.09)
}

.rdtOld {
    color: rgba(9, 30, 66, 0.5);
}

.rdtNew {
    color: rgba(9, 30, 66, 0.5)
}

.rdtActive {
    background-color: rgb(0, 82, 204);
    color: #fff
}

.rdtActive:hover {
    background-color: rgb(0, 101, 255)
}

.rdtActive:active {
    color: rgba(255, 255, 255, 0.8)
}

.rdtCounters {
    display: flex;
    justify-content: center;
    align-items: center
}

.rdtCounter {
    width: 32px
}

.rdtSwitch {
    font-weight: 400;
    padding: 8px;
    border-radius: 3px;
    transition: 0.3s;
}

.rdtSwitch:hover {
    background-color: rgba(9, 30, 66, 0.09);
}

.rdtNext {
    border-radius: 3px;
    transition: 0.3s;
}
.rdtNext:hover {
    background-color: rgba(9, 30, 66, 0.09);
}

.rdtPrev {
    border-radius: 3px;
    transition: 0.3s;
}

.rdtPrev:hover {
    background-color: rgba(9, 30, 66, 0.09);
}

.rdtMonths {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.rdtDisabled {
    color: rgba(9, 30, 66, 0.2);
}

.rdtDisabled:hover {
    background-color: rgba(0,0,0,0);
}

.rdtDisabled:active {
    color: rgba(9, 30, 66, 0.2);
}

.rdtPicker {
    border: 1px solid #4854f0;
}

.form-control {
    height: 30px;
    width: 98px;
    position: absolute;
    top: -43px;
    opacity: 0;
    cursor: pointer;
}
