.rce-mbox-photo--img {
    max-height: 600px !important;
}

#tk_img_clear:hover, #tk_file_clear:hover {
    cursor: pointer;
}

.tk_msg_overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: lightgray;
    z-index: 100;
}

.mainContainer {
    width: 100%;
}

.imageGalleryModul {
    background-color: transparent;
}

.biggerModal {
    max-width: 98vw;
}

.imageGalleryImage {
    max-height: 80vh;
    object-fit: contain;
}

.chatInfoContainer {
    position: relative;
    z-index: 2;
    transition: opacity 0.3s;
}

.flexContainer,.messengerContentBox {
    display: flex;
    position: relative;
}

.pageTitle {
    margin-bottom: 0;
    background-color: var(--primaryColor, #000);
    color: var(--primaryTextColor, #000);
    padding-left: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    position: relative;
    z-index: 5;
}

.pageTitleContainer {
    position: relative;
}

.textBoxContainer {
    position: absolute;
    bottom: 15px;
    left: 0;
    right: 0;
}

.loadContainer,.loadContainerShow{
    position: absolute;
    border-radius: 50%;
    background-color: white;
    left: 0;
    right: 0;
    width: min-content;
    margin-left: auto;
    margin-right: auto;
    border: 5px white solid;
    transition: all 0.3s;
    top: 90px;
}

.loadContainer {
    z-index: 0;
    opacity: 0;
}

.loadContainerShow {
    z-index: 1;
    opacity: 1;
}

.deleteContainer {
    display: flex;
}

