.indicator {
	margin: 8px;
	width: 8px;
	height: 8px;
	border-radius: 4px;
	background-color: #bbb;
}

.active {
	background-color: #4de28b;
}
