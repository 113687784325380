.page {
	width: 100%;
	background-color: #f4f5f7;
	min-height: 100vh;
}

.content {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.card, .card_new{
	width: 100%;
	margin: 12px 0px;
	max-width: 720px;
	background-color: #fff;
}

.animationLong {
	animation: fadeIn 1s;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes highlight {
	0% {
		background: #fff;
	}
	50% {
		background: var(--primaryColor);
		color: var(--primaryTextColor);
	}
	100% {
		background: #fff;
	}
}
.card_new {
	animation: highlight 3s;
}

.cardContent {
	max-height: none;
}

.pagination {
	margin-bottom: 24px;
}
