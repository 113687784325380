.container {
	display: flex;
	align-items: center;
	text-decoration: none;
	padding: 12px;
	width: 100%;
	margin-top: 4px;
	margin-bottom: 4px;
	color: rgb(80, 95, 121);
	border-radius: 3px;
	box-sizing: border-box;
	transition: 0.3s;
}

.container:hover {
	color: rgb(80, 95, 121);
	background-color: rgba(9, 30, 66, 0.04);
	cursor: pointer;
}

.container:active {
	color: rgb(0, 82, 204);
	background-color: rgba(0, 82, 204, 0.09);
}

.active {
	color: rgb(0, 82, 204);
	background-color: rgba(9, 30, 66, 0.04);
}
