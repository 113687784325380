:root {
    --calendar-sidebar-width: 180px;
}

.titleWrapper {
    position: absolute;
}

.title {
    font-size: 17px;
    font-weight: 600;
    color: rgb(80, 93, 113);
    margin: 12px 7px 8px 7px;
}

.icons {
    position: absolute;
    right: 15vw;
    display: flex;
    z-index: 2;
    min-height: 62px;
    align-items: flex-end;
}

@media only screen and (min-width: 1366px) {
    .icons {
        right: 13vw;
    }
}

@media only screen and (min-width: 1695px) {
    .icons {
        right: 9vw;
    }
}

hr {
    border-color: #5a1e420a;
}

.noCalendars {
    width: 100%;
    height: 80vh;
    justify-content: center;
    align-items: center;
    display: flex;
}

.noCalendarsTest {
    font-size: 21px;
    font-weight: bold;
    color: #adadad;
}

.wrapperEmpty {
    text-align: center;
    height: 400px;
    width: 100%;
}

.contentWrapper {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 55%;
    transform: translate(-50%, -50%);
    background-color: rgba(239, 239, 239, 0.8);
    height: 300px;
    width: 300px;
    border-radius: 50%;
}

.textContent {
    margin: 0;
    position: absolute;
    top: 120%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.imageContent {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.notFound {
    font-size: 19px;
    font-weight: bold;
}

.background {
    background-color: #c7c7c7;
}

.calendarWrap {
    display: grid;
    grid-template:
        "header header" auto
        "sidebar calendar" auto / var(--calendar-sidebar-width) 1fr;
}
