.menu {
	width: 304px;
	display: flex;
	box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.07);
	z-index: 1;
}

.mobileMenu {
	width: 100%;
}

@media screen and (max-width: 600px) {
	.menu {
		display: none;
	}
}

@media screen and (min-width: 601px) {
	.mobileMenu {
		display: none;
	}
}

.mobileNavBar {
	display: flex;
	justify-content: space-between;
	/* background-color: rgb(7, 71, 166); */
	background-color: #b3b3b3;
	color: #fff;
}

.leftBar {
	/* background-color: rgb(7, 71, 166); */
	background-color:#b3b3b3;
	height: 100vh;
	width: 48px;
	color: rgb(222, 235, 255);
	font-size: 30px;
	display: flex;
	justify-content: center;
	box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
	z-index: 20;
}

.navigationContainer {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex: 1;
	padding: 12px;
	background-color: rgb(244, 245, 247);
	transition: 0.3s;
	/*overflow-y: auto;*/
	height: 100vh;
	/*width: 240px;*/
	/*overflow: hidden;*/
}

.simpleBarContainer{
	position: relative;
	height: 100%;
}

.simpleBarWrapper {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

.menuContainer {
	min-width: 195px;
	max-width: 232px;
	box-shadow: 3px 0 6px 0 rgba(0,0,0,0.2)
}

.iconContainer {
	cursor: pointer;
	transition: 0.3s;
}

.iconContainer:hover {
	background-color: rgba(9, 30, 66, 0.04);
	border-radius: 3px;
}

.iconContainer:active {
	background-color: rgba(0, 82, 204, 0.09);
}

.icon {
	max-width: 80%;
	max-height: 200px;
	margin: 8px;
}

.iconMobile {
	height: 32px;
	max-width: 50%;
	margin: auto;
}

.commulino {
	color: #fff;
	margin-right: 12px;
}
