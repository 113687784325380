.inlineButton {
    margin: 2px;
    padding: 5px;
    min-height: unset;
    min-width: unset;
    border-radius: 50%;
}

.mediRow {
    display: flex;
    justify-content: space-between;
}

.mediRow > div:first-child {
    flex: 0.7;
}

.mediRow > div:nth-child(2) {
    flex: 0.3;
}

.modalContent {
    max-width: 400px;
}
