.container {
    position: relative;
}

.headRow {
    position: sticky;
    top: 0;
    left: 0;
    right:0;
    align-items: center;
    display: flex;
    justify-content: space-between;
    z-index: 1;
}

.row {
    display: flex;
}

.spaceRight {
    margin-right: 5px;
}