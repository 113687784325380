.indicator {
	border-style: solid;
	border-color: transparent;
	border-top-color: rgb(0, 82, 204);
	border-radius: 50%;
	border-width: 2px;
	width: 16px;
	height: 16px;
	animation: spin 1.5s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.light {
	border-top-color: #fff;
}

.large {
	width: 32px;
	height: 32px;
	border-width: 3px;
}
