.container {
	position: fixed;
	left: 80px;
	bottom: 64px;
	width: 80%;
	max-width: 320px;
	background-color: var(--secondaryColor);
	padding: 12px;
	box-sizing: border-box;
	border-radius: 3px;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.09), 0 6px 20px 0 rgba(0, 0, 0, 0.05);
	transition: 0.4s;
	z-index: 30;
}

@media screen and (max-width: 600px) {
	.container {
		left: 10%;
		right: 10%;
	}
}

.titleContainer {
	display: flex;
	align-items: center;
	color: var(--primaryTextColor, #000) !important;
}

.text {
	color: var(--secondaryTextColor, #000) !important;
}

.title {
	margin-right: 24px;
	color: var(--secondaryTextColor, #000) !important;
}

.hide {
	font-size: 19px;
	margin-bottom: 4px;
	margin-right: 8px;
	position: absolute;
	right: 4px;
	top: 14px;
	transition: 0.3s;
	color: var(--secondaryTextColor, #000) !important;
}

.hide:hover {
	opacity: 0.9;
}

.hide:active {
	opacity: 0.7;
}
