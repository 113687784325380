.row {
	display: flex;
	align-items: center;
	margin-top: 12px;
}

.text {
	margin-bottom: 0;
	margin-right: 8px;
}

.group {
	margin-bottom: 16px;
}
