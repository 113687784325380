.tk_button {
	all: unset;
	appearance: none;
	display: flex;
	flex-direction: row;
	align-items: center;
	text-align: center;
	box-sizing: border-box;
	transition: 0.3s;
	min-height: 30px;
	min-width: 30px;
	border-radius: 4px;
}

.tk_button:hover {
	cursor: pointer;
	filter: contrast(60%);
}
.tk_large {
	min-height: 42px;
}
.tk_fullWidth {
	width: 100%;
}
.tk_content {
	display: flex;
	flex-direction: row;
	align-items: center;
	text-align: center;
	justify-content: center;
	margin: auto;
}

.tk_disabled {}

.tk_disabled:hover {
	cursor: unset;
}


