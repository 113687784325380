.removeButton,.cropButton {
    height: 30px;
    width: 30px;
    border-radius: 50%;
}

.removeButton {
    background-color: rgba(255,255,255,0.7);
}

.buttonContainer {
    position: absolute;
    right: 4px;
    top: 2px;
    padding-bottom: 2px;
}

.addButton {
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: lightblue;
    z-index: 1;
}

.mainContainer, .imageContainer {
    display: flex;
}

.imageContainer {
    transition: width 0.3s ease;
    overflow: hidden;
}

.divRelative {
    position: relative;
}
