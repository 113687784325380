.container {

}
.overlay {

}
.table {
    width: 100%;
}
.thead {
    border-bottom: 1px solid black;
}
.top {
    background-color: var(--primaryColor, #000) !important;
    color: var(--primaryTextColor, #000);
}
.even {
    background-color: lightgrey;
}
.odd {
    background-color: #4de28b;
}
.td {
    padding-left: 10px;
}
.deaktiviert {
    background: gray;
}
.noBackground {
    background: white;
}

.actionCell {
    width: 1px;
    padding: .25rem;
}
.actionWrap {
    display: flex;
    gap: .5rem;
}
