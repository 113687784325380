.container {
	width: 100%;
	display: flex;
	flex: 1;
	justify-content: center;
	overflow-y: auto;
	align-self: stretch;
}

.content {
	flex: 1;
	max-width: 1200px;
	box-sizing: border-box;
	align-self: start;
}

.loading {
	display: flex;
	align-items: center;
	position: absolute;
	bottom: 24px;
	right: 24px;
	transition: 0.5s;
}

.indicator {
	border-top-color: #505d71;
	margin-left: 16px;
}
