.container {
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 24px;
}

.name {
	margin-left: 8px;
}
