.container {
	padding-bottom: 4px;
	margin-top: 24px;
	width: 100%;
	border-bottom: 1px solid transparent;
}

.title {
	color: #172b4d !important;
	margin-bottom: 0 !important;
}

.input {
	all: unset;
	width: 100%;
	font: inherit;
	font-size: 21px;
	font-weight: 600;
	color: #172b4d;
	height: 30px;
}
