.button {
	all: unset;
	appearance: none;
	display: flex;
	flex-direction: row;
	align-items: center;
	text-align: center;
	padding-left: 8px;
	padding-right: 8px;
	margin-top: 4px;
	margin-bottom: 4px;
	border-radius: 3px;
	box-sizing: border-box;
	transition: 0.3s;
	min-height: 30px;
	color: #fff;
	-webkit-text-fill-color: #fff;
	background-color: rgb(0, 82, 204);
}

.button:hover {
	cursor: pointer;
	background-color: rgb(0, 101, 255);
}

.button:active {
	color: #fff;
	-webkit-text-fill-color: #fff;
	background-color: rgb(7, 71, 166);
}

.large {
	min-height: 42px;
}

.transparent {
	color: rgb(80, 95, 121);
	-webkit-text-fill-color: rgb(80, 95, 121);
	background-color: transparent;
}

.light {
	color: rgb(80, 95, 121);
	-webkit-text-fill-color: rgb(80, 95, 121);
	background-color: rgb(237, 239, 242);
}

.transparent:hover,
.light:hover {
	background-color: rgb(233, 235, 238);
}

.transparent:active,
.light:active {
	color: rgb(0, 82, 204);
	-webkit-text-fill-color: rgb(0, 82, 204);
	background-color: rgb(233, 240, 250);
}

.fullWidth {
	width: 100%;
}
