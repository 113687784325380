.row {
    display: flex;
    align-items: center;
    background-color: white;
    margin-left: 4px;
    margin-right: 4px;
    border-radius: 5px;
    padding-top: 2px;
    padding-bottom: 2px;
    transition: background-color 0.2s ease;
    border-color: white;
    border-width: 1px;
    margin-bottom: 5px;
    flex: 1;
}

.row:hover {
    background-color: #dadada;
    cursor: pointer;
}

.row:active {
    border-color: var(--secondaryColor,white);
}
