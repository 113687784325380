.modal {
    height: 50vh;
}

.modalContent {
    height: 100%;
}

.pickerHead {
    background-color: var(--primaryColor,#FFF);
    color: var(--primaryTextColor,#000);
    padding-left: 4px;
    padding-right: 4px;
}

.row {
    display: flex;
    align-items: center;
    flex-flow: row wrap;
}