.list_container, .raster_container {
    overflow: hidden;
    position: relative;
}

.list_container {
    display: flex;
    align-items: center;
    padding: 10px 5px;
    border-radius: 5px;
}

.selected {
    background-color: rgba(0,0,255,0.1);
}


.raster_container {
    height: 150px;
    width: 150px;
    padding: 5px;
    margin: 5px;
    box-shadow: 6px 6px 7px -5px rgba(0,0,0,0.76);
    -webkit-box-shadow: 6px 6px 7px -5px rgba(0,0,0,0.76);
    -moz-box-shadow: 6px 6px 7px -5px rgba(0,0,0,0.76);
    border-radius: 5px;
    border: 1px rgba(0,0,0,0.1) solid;
}

.list_icon {
    margin-right: 5px;
}
.raster_icon {
    width: 100%;
    height: 68%;
}

.raster_container:hover,.list_container:hover {
    cursor: pointer;
    background-color: rgba(212, 239, 255, 0.5);
}