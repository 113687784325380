.iconTextContainer {
    cursor: pointer;
    padding: 2px 10px
}

.iconTextContainer:hover {
    background-color: #eaecf0;
    border-radius: 2px;
}

.iconTextWrapper {
    display: flex;
}

.icon {
    font-size: 23px;
    margin-right: 5px;
}

.iconDis {
    margin: 16px 10px 8px 10px;;
    font-size: 20px;
    color: #cccccc;
}

.tippy {
    background-color: rgb(249, 247, 255);
    color: #0052cc;
    transform: scale(0.85);
}

@media (min-width: 430px) {
    .container_width {
        width: 500px;
    }
    .modal_width {
        width: auto;
    }
}
.backgroundUpdate {
    background: #e5fbe5;

}
.addEventContent {
    display: flex;
    flex-flow: column wrap;
    /*min-height: 650px;*/
    background-color: white;
    border-radius: 4px;
}

.formInput {
    background: white;
}

.formErrorMessage {
    border-color: #ff5500;
}

.formInputInterval, .formInput:hover {
    background: rgba(244, 245, 247, 0.2);
    border-color: #414141;
    cursor: auto;
}

.formInputInterval {
    float: right;
}

.typo {
    font-size: 21px;
    font-weight: 600;
    color: #505d71;
    margin-bottom: 12px
}

.titlesWrapper {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.typoUpdate {
    font-size: 21px;
    font-weight: 600;
    color: rgb(67 160 71);
    margin-bottom: 12px;
    cursor: pointer;
}

.recurrenceButton {
    cursor: pointer;
    color: rgb(67 160 71);
    margin-bottom: 12px;
}

.returnIcon{
    width: 18px;
    padding: 5px;
    border-radius: 50%;
}

.returnIcon:hover {
    background-color: rgba(244, 245, 247, 0.7);
}

.recurrenceLabel {
    padding: 0 5px;
}

.recurrenceLabel:hover {
    background-color: rgba(244, 245, 247, 0.7);
}

.modalGrid {
    width: 100%;
    box-sizing: border-box;
    align-items: center;
    padding: 8px;
    display: flex;
    margin: 0 auto;
    flex-flow: row wrap;
}

.imageSelect {
    overflow: hidden;
    align-self: flex-start;
    width: calc(8% - 16px);
    margin: 10px 8px 30px 8px;
}

.imgBackground {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    transform: translate3d(0, 0, 0);
    will-change: transform;
}

.img {
    width: 100%;
    position: relative;
}

.imageNoSelect {
    width: calc(8% - 16px);
    position: relative;
}

.imageFileObjectTrueEdit{
    position: absolute;
    bottom: 6px;
    right: 2px;
    font-size: 21px;
}

.imageFileObjectTrueClose {
    position: absolute;
    bottom: 6px;
    left: 2px;
    font-size: 18px;
    border-radius: 15px;
    background: white;
    cursor: pointer;
}

.filePikerImage {
    position: absolute;
    bottom: 5px;
    right: 0;
    width: 30px;
    opacity: 0;
    cursor: pointer;
}

.imagePickerUpdateEvent {
    position: absolute;
    bottom: -8px;
    right: 2px;
    border: 1px solid black;
    height: 20px;
    width: 20px;
    cursor: pointer;
    /*opacity: 0;*/
}

.filePikerImageEditEvent {
    position: absolute;
    bottom: -3px;
    right: 4px;
    height: 20px;
    width: 26px;
    cursor: pointer;
    opacity: 0;
}

.spanImageSelected {
    position: absolute;
    color: blue;
    width: max-content;
    left: 0;
    bottom: -20px;
}

.icoBackground {
    text-align: center;
    background-size: cover;
    background-position: center;
}

.row,.errorRow {
    display: flex;
    width: 100%;
    min-height: 100px;
    align-items: flex-start;
    flex-flow: row;
    margin-bottom: 5px;
}
.errorRow {
    min-height: unset;
}

.reactSelect {
    all: unset;
    border: 1px solid rgb(237, 239, 242);
    border-radius: 3px;
    padding: 8px;
    width: 100%;
    margin-top: 4px;
    margin-bottom: 4px;
    display: inline-block;
    box-sizing: border-box;
    cursor: default;
    background: white;
}

.reactSelect:hover {
    background: rgba(244, 245, 247, 0.2);
}

.reactSelectMessage {
    all: unset;
    border: 1px solid #ff5500;
    border-radius: 3px;
    padding: 8px;
    width: 100%;
    margin-top: 4px;
    margin-bottom: 4px;
    display: inline-block;
    box-sizing: border-box;
    cursor: default;
    background: white;
}

.reactSelectMessage:hover {
    background: rgba(244, 245, 247, 0.2);
}

.cancelButton {
    margin-right: 8px;
    margin-top: 0;
    margin-bottom: 0;
}

.label {
    font-size: 15px;
    margin-bottom: 8px;
    font-weight: 500;
    white-space: pre-wrap;
}

.DateTimeContainer {
    display: flex;
    flex-flow: row;
    width: 100%;
}

.center {
    text-align: center;
}

.labelWidth {
    width:215px;
    margin-bottom: 10px;
}

.number{
    position: absolute;
    font-size: 12px;
    right: 0;
}

.divButtons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    margin: 10px 0;
    flex-grow: 1;
    position: relative;
}

.dateTimePicker {
    margin: -45px;
    min-width: 300px;
    background-color: #e8f6ff;
}

.dateTimeIconClose {
    font-size: 25px;
    cursor: pointer;
    color: #75736e;
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    right: -8px;
}


.dateTimeIconClose:hover {
    background-color: #eeeeee;
}

.dateTimeIcon {
    font-size: 25px;
    cursor: pointer;
    color: #75736e;
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    right: -8px;
}

.divWrapperIcon {
    width: 35px;
    height: 35px;
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%); right: -64px;
}

.divWrapperIconUhr {
    width: 35px;
    height: 35px;
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%); right: -64px;
}

.divWrapperIcon:hover {
    background-color: #eeeeee;
    cursor: pointer;
}

.dateTimeUhr {
    border: 1px solid #f9f9f9;
    margin-top: 5px;
    text-align: right;
    padding: 0 5px;
}

.dateTimeUhr:hover {
    background-color: #eeeeee;
    cursor: pointer;
}

.information {
    color: white;
    font-size: 11px;
    text-align: justify;
    padding: 2px 5px;
}

.informationWrapper{
    position: absolute;
    top: 6%;
    right: -2%;
}

.informationFrame {
    width: 300px;
    height: 158px;
    padding: 15px;
    position: absolute;
    top: -192px;
    border: 1px solid #808080;
    right: -293px;
    background: #64be69;
    border-radius: 10px;
}

.closeIcon {
    color: white;
    top: 4px;
    position: absolute;
    right: 8px;
    cursor: pointer;
}

.iconSize {
    display: flex;
    align-items: center;
}

.standardSpace {
    margin: 0 8px;
    position: relative;
}

.titleContainer {
    width: calc(65% - 16px);
}

.titleContainerUser {
    width: calc(68% - 16px);
}

.calendarContainer {
    width: calc(29% - 16px);
}

.calendarContainerUser {
    width: calc(32% - 16px);
}

.intervalContainer {
    width: calc(11.6% - 16px);
    display: flex;
    position: relative;
    text-align: center;
}

.allDayContainer {
    width: calc(9% - 16px);
}

.editRecurrence {
    width: calc(25% - 16px);
    min-height: 73px;
    margin: 0 20px;
}

.checkBoxAllDayContainer{
    width: 100%;
    height: 65px;
    position: relative;
}

.checkBoxEditContainer {
    width: fit-content;
    min-height: 40px;
    align-items: center;
}

.checkBoxAllDay {
    position: absolute;
    top: 30%;
    right: 25%;
    transform: translate(-50%, -50%);
}

.checkBoxEdit {
    margin-right: 10px;
}

.repeatContainer {
    width: calc(27% - 16px);
}

.emptyRecurrence {
    width: calc(36% - 16px);
}

.dateTime{
    flex-grow: 1;
    justify-content: end;
    width: calc(27% - 16px);
}

.beschreibungContainer {
    width: calc(100% - 16px);
    position: relative;
    margin: 0 8px;
}

.updateCloseIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.updateCloseDiv {
    position: relative;
    background: rgb(233,235,238);
    width: 27px;
    height: 30px;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 5px;
}

.intervalWarning{
    top:-25%;
    left:-145%;
    position: absolute;
    width: 400px;
}

.createdUpdatedDiv {
    width: 100%;
    display: flex;
    font-size: 12px;
}

.boldText {
    font-weight: bold;
    margin-right: 10px;
}

.flex {
    display: flex;
}

.tentativeText{
    position: absolute;
    top: 0;
    font-size: 10px;
}
