.contentCalendars {
    position: relative;
    display: flex
}

.title {
    text-align: center;
    font-weight: bold;
    font-size: large;
}

.iconDiv {
    display: flex;
}

.iconSize {
    font-size: 30px
}
.iconSizeDescription {
    font-size: 23px;
    margin-top: 5px;
    margin-left: 6px;
}

.date {
    line-height: 35px;
    margin-left: 10px;
}

.noDescription {
    line-height: 35px;
    margin-left: 10px;
    font-weight: 500;
    color: #bfbfbf;
}

.bold {
    font-weight: bold;
}

.description {
    margin: 5px 0 0 5px;
}

.modalContent {
    margin: auto;
    width: fit-content;
}

.maxWidth {
    max-width: 770px;
}

.closeIcon {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 20px;
    cursor: pointer;
}

.iconEventDiv {
    position: absolute;
    right: 15vw;
    display: flex;
    z-index: 2;
    min-height: 62px;
    align-items: flex-end;
}

@media only screen and (min-width: 1366px) {
    .iconEventDiv {
        right: 13vw;
    }
}

@media only screen and (min-width: 1695px) {
    .iconEventDiv {
        right: 9vw;
    }
}

.backgroundLinear {
    opacity: 0.75;
    position: relative;
    --stripe-opacity: 0.3;
    background: repeating-linear-gradient( 135deg, transparent, transparent 10px, rgba(0,0,0,var(--stripe-opacity)) 10px, rgba(0,0,0,var(--stripe-opacity)) 20px );
}
