.page {
	width: 100%;
	background-color: #f4f5f7;
	min-height: 100vh;
}

.content {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.card {
	width: 100%;
	margin: 12px 0px;
	max-width: 720px;
	background-color: #fff;
}

.cardContent {
	max-height: none;
}

.pagination {
	margin-bottom: 24px;
}
