.tag {
	background-color: rgba(255, 255, 255, 0.25);
    border: solid 1px rgb(187, 189, 191);
	border-radius: 4px;
	overflow: hidden;
	margin: 2px;
	display: inline-block;
	cursor: inherit;
    line-height: 1;
    padding: 4px 6px;
	min-height: 1em;
}
