.rce-mbox {
    max-width: 80%;
    white-space: pre-wrap;
}
.rce-mbox-reply-message {
    white-space: pre-wrap !important;
}
.tk_clickTextMSG:hover {
    cursor: pointer;
}
.rce-container-mbox:hover .tk_DeleteButton {
    opacity: 1;
    transition: opacity 0.3s ease;
}
.tk_DeleteButton {
    opacity: 0;
    transition: opacity 0.3s ease;
}
.rce-mbox-file {
    position: relative;
    z-index: 10;
}
