.editor {
    border: solid 2px #ccc;
    border-radius: 4px;
    overflow: hidden;
    transition-delay: .125s;
    transition: border-color .3s ease-in-out;
}

.editorFocused {
    border-color: var(--primaryColor, #f0f);
    transition-delay: 0s;
}

.editor__content {
    padding: 4px;
    max-height: 500px;
    overflow: auto;
}

.image {
    width: 100%;
    object-fit: contain;
}

.embed {
    width: calc(100% - 4px);
    object-fit: contain;
}
